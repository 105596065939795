<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
    @click.native="handleRouteClick"
  >
    <li
      class="submenu-item"
      :class="[
        modifierClass,
        {
          'submenu-item-active': isActive,
          'submenu-item-truncated': shouldTruncate,
        },
      ]"
      @click="navigate"
    >
      <a :href="href" class="submenu-item-link">
        <span v-if="icon && !isInsideDropdown" class="submenu-item-inner">
          <fluent-icon
            class="submenu-item-icon"
            :class="{
              'submenu-item-icon-active': isActive,
            }"
            :icon="icon"
            :size="17"
            :viewBox="isMessengerIcon ? '-4 -4 24 24' : '0 0 24 24'"
          />
        </span>

        <span
          v-if="labelColor"
          class="submenu-item-label"
          :style="{ backgroundColor: labelColor }"
        />
        <div
          class="items-center flex overflow-hidden whitespace-nowrap text-ellipsis w-full justify-between"
        >
          <span
            :title="menuTitle"
            class="submenu-item-text"
            :class="{
              'submenu-item-text-active': isActive,
              'submenu-item-text-truncated': shouldTruncate,
            }"
          >
            {{ label }}
          </span>
          <span
            v-if="showChildCount"
            class="rounded-sm text-xxs mx-1 py-0 px-1 text-accent dark:text-accent-dark bg-accent-low dark:bg-accent-dark-low"
            :class="
              isCountZero
                ? `text-accent dark:text-accent-dark`
                : `text-accent dark:text-accent-dark`
            "
          >
            {{ childItemCount }}
          </span>
        </div>
        <span
          v-if="warningIcon"
          class="inline-flex rounded-sm mr-1 p-[1px] bg-warning-subtle dark:bg-warning-dark-faint"
        >
          <fluent-icon
            v-tooltip.top-end="$t('SIDEBAR.REAUTHORIZE')"
            class="text-xxs text-warning"
            :icon="warningIcon"
            size="12"
          />
        </span>
      </a>
    </li>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
    isInsideDropdown: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showIcon() {
      return {
        'overflow-hidden whitespace-nowrap text-ellipsis': this.shouldTruncate,
      };
    },
    isCountZero() {
      return this.childItemCount === 0;
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
    // somehow messenger icon not aligned properly, need to adjust it
    // TODO: need proper svg icon
    isMessengerIcon() {
      return this.icon === 'brand-messenger';
    },
    modifierClass() {
      let baseClass = ['submenu-item'];

      if (this.isInsideDropdown) {
        baseClass.push('submenu-item--dropdown');
      }

      return baseClass;
    },
  },
  methods: {
    handleRouteClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.submenu-item {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  m-0
  mt-1
  text-sm
  text-secondary
  dark:text-secondary
  hover:bg-neutral-medium
  hover:text-secondary
  dark:hover:bg-[#222527]
  border
  border-transparent;
}
.submenu-item-active {
  @apply bg-white
  dark:bg-[#222527]
  border-primary-dark
  dark:border-[#474D52];

  &:hover {
    @apply text-primary dark:text-primary-dark cursor-default bg-white dark:bg-[#222527];
  }
}

.submenu-item.submenu-item--dropdown {
  &:hover {
    @apply bg-neutral-low dark:bg-primary;
  }

  .submenu-item-text {
    @apply text-primary dark:text-primary-dark font-normal;
  }

  &.submenu-item-active {
    @apply bg-neutral-low dark:bg-primary;

    &:hover {
      @apply bg-neutral-low dark:bg-primary;
    }
  }
}

.submenu-item-truncated {
  @apply text-ellipsis overflow-hidden whitespace-nowrap max-w-full;
}
.submenu-item-inner {
  @apply inline-flex items-center justify-center rounded-sm mr-1.5 rtl:mr-0 rtl:ml-1.5 w-[18px] h-[16px] relative;
}
.submenu-item-icon {
  @apply text-xxs text-secondary absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
.submenu-item-icon-active {
  @apply text-primary dark:text-primary-dark;
}
.submenu-item-label {
  @apply inline-flex rounded-sm bg-slate-100 h-3 w-3.5 mr-1.5 rtl:mr-0 rtl:ml-1.5 border border-slate-50 dark:border-slate-900;
}
.submenu-item-link {
  @apply inline-flex
  text-left
  max-w-full
  w-full
  items-center
  text-secondary
  dark:text-secondary
  h-[33px]
  p-2;
}
.submenu-item-text {
  @apply text-sm text-secondary dark:text-secondary;
}
.submenu-item-text-active {
  @apply text-primary dark:text-primary-dark;
}
.submenu-item-text-truncated {
  @apply text-ellipsis overflow-hidden whitespace-nowrap max-w-full;
}

::v-deep {
  .submenu-item-icon,
  .submenu-item-icon.dark,
  .submenu-item-icon.dark-os {
    path {
      // if first path have color there is border outside.
      // icon that affected: `brand-meta`, `brand-mail`, `shopify-globe`
      &:first-child {
        @apply stroke-none;
      }
      @apply stroke-secondary;
    }
  }

  .submenu-item-icon-active {
    path {
      @apply stroke-secondary text-secondary;
    }
  }

  .submenu-item-icon-active.dark,
  .submenu-item-icon-active.dark-os {
    path {
      @apply stroke-secondary-dark text-secondary-dark;
    }
  }
}
</style>
