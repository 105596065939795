var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuItemVisible),expression:"isMenuItemVisible"}],staticClass:"mt-1"},[(_vm.hasSubMenu)?_c('div',{staticClass:"flex justify-between"},[_c('span',{staticClass:"subhead"},[_vm._v("\n      "+_vm._s(_vm.$t(`SIDEBAR.${_vm.menuItem.label}`))+"\n    ")]),_vm._v(" "),(_vm.menuItem.showNewButton)?_c('div',{staticClass:"flex items-center"},[_c('woot-button',{staticClass:"p-0 ml-2",attrs:{"size":"tiny","variant":"clear","color-scheme":"secondary","icon":"add"},on:{"click":_vm.onClickOpen}})],1):_vm._e()]):_c('router-link',{staticClass:"menu-item",class:[_vm.computedClass, { 'menu-item-disabled': _vm.isDisabled }],attrs:{"to":!_vm.isDisabled ? _vm.menuItem && _vm.menuItem.toState : ''}},[_c('fluent-icon',{staticClass:"min-w-[1rem] mr-1.5 rtl:mr-0 rtl:ml-1.5",attrs:{"icon":_vm.menuItem.icon,"size":"14","view-box":"0 0 24 24","force-light-color":_vm.menuItem.forceLightColor}}),_vm._v("\n    "+_vm._s(_vm.$t(`SIDEBAR.${_vm.menuItem.label}`))+"\n    "),(_vm.showChildCount(_vm.menuItem.count))?_c('span',{staticClass:"rounded-md text-xxs font-medium mx-1 py-0 px-1",class:{
        'text-slate-200 dark:text-slate-200': _vm.isCountZero && !_vm.isActiveView,
        'text-slate-200 dark:text-slate-200': !_vm.isCountZero && !_vm.isActiveView,
        'bg-violet-700 dark:bg-violet-700 text-slate-100 dark:text-slate-100':
          _vm.isActiveView,
        'bg-violet-700 dark:bg-violet-700': !_vm.isActiveView,
      }},[_vm._v("\n      "+_vm._s(`${_vm.menuItem.count}`)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.menuItem.beta)?_c('span',{staticClass:"px-1 mx-1 inline-block font-medium leading-4 border border-green-400 text-green-500 rounded-lg text-xxs",attrs:{"data-view-component":"true","label":"Beta"}},[_vm._v("\n      "+_vm._s(_vm.$t('SIDEBAR.BETA'))+"\n    ")]):_vm._e()],1),_vm._v(" "),(_vm.hasSubMenu)?_c('ul',{staticClass:"list-none ml-0 mb-0"},[_vm._l((_vm.menuItem.children),function(child){return _c('secondary-child-nav-item',{key:child.id,attrs:{"to":child.toState,"label":child.label,"label-color":child.color,"should-truncate":child.truncateLabel,"icon":_vm.computedInboxClass(child),"warning-icon":_vm.computedInboxErrorClass(child),"show-child-count":_vm.showChildCount(child.count),"child-item-count":child.count}})}),_vm._v(" "),(_vm.showItem(_vm.menuItem))?_c('router-link',{attrs:{"to":_vm.menuItem.toState,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"pl-1"},[_c('a',{attrs:{"href":href}},[_c('woot-button',{staticClass:"btn-add",attrs:{"size":"tiny","variant":"clear","color-scheme":"secondary","icon":"add","data-testid":_vm.menuItem.dataTestid},on:{"click":e => _vm.newLinkClick(e, navigate)}},[_vm._v("\n            "+_vm._s(_vm.$t(`SIDEBAR.${_vm.menuItem.newLinkTag}`))+"\n          ")])],1)])]}}],null,false,1344565465)}):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }