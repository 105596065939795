<template>
  <div>
    <h6>{{ $t("KLAVIYO.CONTACT_PANEL.LISTS_HEADER") }}</h6>
    <a v-for="list in lists" v-bind:key="list.id"
      :href="`https://klaviyo.com/list/${list.id}`"
      target="_blank"
      class="link"
    >
      {{ list.name }}
    </a>

    <hr>

    <h6>{{ $t("KLAVIYO.CONTACT_PANEL.SEGMENTS_HEADER") }}</h6>
    <a v-for="segment in segments" v-bind:key="segment.id"
       :href="`https://klaviyo.com/list/${segment.id}`"
       target="_blank"
       class="link"
    >
      {{ segment.name }}
    </a>

    <hr>

    <h6>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES_HEADER") }}</h6>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.EMAIL") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile['email'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.PHONE_NUMBER") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile['phone_number'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.FIRST_NAME") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile['first_name'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.LAST_NAME") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile['last_name'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.EXTERNAL_ID") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile['external_id'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.CREATED") }}</label>
      <span class="value">{{ formatDate(chat.klaviyo_data.profile['created']) || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.UPDATED") }}</label>
      <span class="value">{{ formatDate(chat.klaviyo_data.profile['updated']) || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ATTRIBUTES.LAST_EVENT_DATE") }}</label>
      <span class="value">{{ formatDate(chat.klaviyo_data.profile['last_event_date']) || '-' }}</span>
    </div>

    <hr>

    <h6>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION_HEADER") }}</h6>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.ADDRESS_1") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['address1'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.ADDRESS_2") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['address2'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.CITY") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['city'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.COUNTRY") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['country'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.REGION") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['region'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.ZIP_CODE") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['zip'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.TIMEZONE") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['timezone'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.IP") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['ip'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.LATITUDE") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['latitude'] || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.LOCATION.LONGITUDE") }}</label>
      <span class="value">{{ chat.klaviyo_data.profile.location['longitude'] || '-' }}</span>
    </div>

    <hr>

    <h6>{{ $t("KLAVIYO.CONTACT_PANEL.PROPERTIES_HEADER") }}</h6>

    <div v-for="property in properties" v-bind:key="property.name">
      <label>{{ property.name }}</label>
      <span class="value">{{ property.value }}</span>
    </div>

    <hr>

    <h6>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS_HEADER") }}</h6>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.AVERAGE_DAYS_BETWEEN_ORDERS") }}</label>
      <span class="value">{{ analytics.average_days_between_orders || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.AVERAGE_ORDER_VALUE") }}</label>
      <span class="value">{{ analytics.average_order_value || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.CHURN_PROBABILITY") }}</label>
      <span class="value">{{ analytics.churn_probability || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.HISTORIC_CLV") }}</label>
      <span class="value">{{ analytics.historic_clv || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.EXPECTED_DATE_OF_NEXT_ORDER") }}</label>
      <span class="value">{{ expectedDateOfNextOrder || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.HISTORIC_CLV") }}</label>
      <span class="value">{{ analytics.historic_clv || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.HISTORIC_NUMBER_OF_ORDERS") }}</label>
      <span class="value">{{ analytics.historic_number_of_orders || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.PREDICTED_CLV") }}</label>
      <span class="value">{{ analytics.predicted_clv || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.PREDICTED_NUMBER_OF_ORDERS") }}</label>
      <span class="value">{{ analytics.predicted_number_of_orders || '-' }}</span>
    </div>

    <div>
      <label>{{ $t("KLAVIYO.CONTACT_PANEL.ANALYTICS.TOTAL_CLV") }}</label>
      <span class="value">{{ analytics.total_clv || '-' }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    chat: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    properties() {
      const properties = this.chat.klaviyo_data.profile['properties'] || {};

      return Object.keys(properties).sort().map((key) => {
        return { name: key, value: this.formatPropertyValue(properties[key]) };
      });
    },
    segments() {
      return this.chat.klaviyo_data.segments;
    },
    lists() {
      return this.chat.klaviyo_data.lists;
    },
    analytics() {
      return this.chat.klaviyo_data.profile.predictive_analytics;
    },
    expectedDateOfNextOrder() {
      if (!this.analytics.expected_date_of_next_order) return;

      return moment(analytics.expected_date_of_next_order).format('MM/DD/YYYY');
    },
  },
  methods: {
    formatDate(t) {
      if (!t) return;

      return moment(t).format('MM/DD/YYYY');
    },
    formatPropertyValue(value) {
      if (Array.isArray(value)) return value.join(', ');
      else if (typeof value === 'boolean') return this.$t(`KLAVIYO.${value ? 'TRUE' : 'FALSE'}`);
      else return value;
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  @apply text-sm font-normal text-secondary mb-2 mt-4;
}

.value {
  @apply text-sm font-medium;
}

h6 {
  @apply text-sm font-semibold mt-2 dark:text-secondary;
}

.link {
  @apply block py-3 px-4 hover:bg-[#F4F3F5] rounded-lg text-primary;
}

hr {
  @apply border-secondary-low mt-2 dark:border-neutral-dark-medium;
}
</style>
