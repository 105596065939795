<template>
  <div
    class="relative flex items-start flex-grow-0 flex-shrink-0 w-auto max-w-full px-4 py-0 border-t-0 border-b-0 border-l-2 border-r-0 border-transparent border-solid cursor-pointer conversation hover:bg-slate-25 dark:hover:bg-slate-800 group"
    :class="{
      'active bg-slate-25 dark:bg-slate-800 border-woot-500': isActiveChat,
      'unread-chat': hasUnread,
      'has-inbox-name': showInboxName,
      'conversation-selected': selected,
    }"
    @mouseenter="onCardHover"
    @mouseleave="onCardLeave"
    @click="onCardClick"
    @contextmenu="openContextMenu($event)"
  >
    <label v-if="hovered || selected" class="checkbox-wrapper" @click.stop>
      <input
        :value="selected"
        :checked="selected"
        class="checkbox"
        type="checkbox"
        @change="onSelectConversation($event.target.checked)"
      />
    </label>
    <thumbnail
      v-if="bulkActionCheck"
      :src="currentContact.thumbnail"
      :badge="inboxBadge"
      class="columns"
      :username="currentContact.name"
      :status="currentContact.availability_status"
      size="40px"
    />
    <div
      class="px-0 py-3 border-b group-last:border-transparent group-hover:border-transparent border-slate-50 dark:border-slate-800/75 columns"
      :class="{ 'pb-6': isAMetaCommentInbox }"
    >
      <div class="flex justify-between">
        <inbox-name v-if="showInboxName" :inbox="inbox" />
        <div class="flex gap-2 ml-2 rtl:mr-2 rtl:ml-0">
          <span
            v-if="showAssignee && assignee.name"
            class="text-slate-500 dark:text-slate-400 text-xs font-medium leading-3 py-0.5 px-0 inline-flex text-ellipsis overflow-hidden whitespace-nowrap"
          >
            <fluent-icon
              icon="person"
              size="12"
              class="text-slate-500 dark:text-slate-400"
            />
            {{ assignee.name }}
          </span>
          <priority-mark :priority="chat.priority" />
        </div>
      </div>
      <h4
        class="conversation--user text-sm my-0 mx-2 capitalize pt-0.5 text-ellipsis overflow-hidden whitespace-nowrap w-[60%] text-slate-900 dark:text-slate-100"
      >
        {{ currentContact.name }}
      </h4>
      <message-preview
        v-if="lastMessageInChat"
        :message="lastMessageInChat"
        class="conversation--message my-0 mx-2 leading-6 h-6 max-w-[72%] w-[16.875rem] text-sm text-slate-700 dark:text-slate-200"
      />
      <p
        v-else
        class="conversation--message text-slate-700 dark:text-slate-200 text-sm my-0 mx-2 leading-6 h-6 max-w-[72%] w-[16.875rem] overflow-hidden text-ellipsis whitespace-nowrap"
      >
        <fluent-icon
          size="16"
          class="-mt-0.5 align-middle inline-block text-slate-600 dark:text-slate-300"
          icon="info"
        />
        <span>
          {{ $t(`CHAT_LIST.NO_MESSAGES`) }}
        </span>
      </p>
      <div class="absolute flex flex-col conversation--meta right-4 top-4">
        <span class="ml-auto font-normal leading-4 text-black-600 text-xxs">
          <time-ago
            :last-activity-timestamp="chat.timestamp"
            :created-at-timestamp="chat.created_at"
          />
        </span>
        <span
          class="unread shadow-lg rounded-full hidden text-xxs font-semibold h-4 leading-4 ml-auto mt-1 min-w-[1rem] px-1 py-0 text-center text-white bg-green-400"
        >
          {{ unreadCount > 9 ? '9+' : unreadCount }}
        </span>
        <!-- AI comment moderation -->
        <div
          v-if="
            $featureFlags['autohide-negative-comments'] &&
            isAMetaCommentInbox &&
            !isInsideContactConversation
          "
          class="moderation"
        >
          <div
            v-if="hasMetaAiDecision"
            v-tooltip="{
              content: `${aiDecisionData.category}: ${aiDecisionData.reason}`,
            }"
            class="moderation__item"
          >
            <Icon
              size="14"
              view-box="-0.5 -0.5 13 13"
              icon="memory"
              type="outline"
              class="moderation__icon"
              :icons="iconLib"
            />
          </div>
          <!-- Like icon -->
          <div
            v-if="!isInstagramComment"
            v-tooltip="{
              content: isOnMetaInbox ? tooltipForLike : undefined,
            }"
            class="moderation__item"
            :class="{
              'hover:bg-transparent hover:dark:bg-transparent': !isOnMetaInbox,
              'hover:bg-neutral-medium hover:dark:bg-neutral-dark-medium':
                isOnMetaInbox,
            }"
            @click.stop="handleLikeComment"
          >
            <spinner
              v-if="loadingState('like')"
              size="small"
              class="moderation__spinner"
            />
            <fluent-icon
              v-else
              icon="thumb-up"
              type="solid"
              size="14"
              view-box="0 1 14 14"
              class="moderation__icon moderation__icon--action"
              :class="{
                '!text-accent !dark:text-accent-dark': shouldShowLikeIcon,
              }"
            />
          </div>
          <!-- Eye icon -->
          <div
            v-tooltip="{
              content: isOnMetaInbox ? tooltipForHide : undefined,
            }"
            class="moderation__item"
            :class="{
              'hover:bg-transparent hover:dark:bg-transparent': !isOnMetaInbox,
              'hover:bg-neutral-medium hover:dark:bg-neutral-dark-medium':
                isOnMetaInbox,
            }"
            @click.stop="handleHideComment"
          >
            <spinner
              v-if="loadingState('hide')"
              size="small"
              class="moderation__spinner"
            />
            <fluent-icon
              v-else
              :icon="shouldShowHideIcon ? 'eye-hide' : 'eye-show'"
              type="solid"
              size="14"
              view-box="0 0 14 14"
              class="moderation__icon moderation__icon--action"
              :class="{
                '!text-accent !dark:text-accent-dark': shouldShowHideIcon,
                '!hover:bg-none !dark:hover:bg-none': !isOnMetaInbox,
              }"
            />
          </div>
        </div>
      </div>
      <card-labels :conversation-id="chat.id" />
    </div>
    <woot-context-menu
      v-if="showContextMenu"
      ref="menu"
      :x="contextMenu.x"
      :y="contextMenu.y"
      @close="closeContextMenu"
    >
      <conversation-context-menu
        :status="chat.status"
        :inbox-id="inbox.id"
        :priority="chat.priority"
        :has-unread-messages="hasUnread"
        @update-conversation="onUpdateConversation"
        @assign-agent="onAssignAgent"
        @assign-label="onAssignLabel"
        @assign-team="onAssignTeam"
        @mark-as-unread="markAsUnread"
        @assign-priority="assignPriority"
      />
    </woot-context-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Thumbnail from '../Thumbnail.vue';
import MessagePreview from './MessagePreview.vue';
import conversationMixin from '../../../mixins/conversations';
import timeMixin from '../../../mixins/time';
import router from '../../../routes';
import { frontendURL, conversationUrl } from '../../../helper/URLHelper';
import InboxName from '../InboxName.vue';
import inboxMixin from 'shared/mixins/inboxMixin';
import ConversationContextMenu from './contextMenu/Index.vue';
import alertMixin from 'shared/mixins/alertMixin';
import TimeAgo from 'dashboard/components/ui/TimeAgo.vue';
import CardLabels from './conversationCardComponents/CardLabels.vue';
import PriorityMark from './PriorityMark.vue';
import icons from 'shared/components/FluentIcon/icons.json';
import Icon from 'shared/components/FluentIcon/Icon.vue';
import Spinner from 'shared/components/Spinner';
import { META_COMMENT_TYPE_INSTAGRAM } from 'shared/constants/messages';

export default {
  components: {
    CardLabels,
    InboxName,
    Thumbnail,
    ConversationContextMenu,
    TimeAgo,
    MessagePreview,
    PriorityMark,
    Icon,
    Spinner,
  },

  mixins: [inboxMixin, timeMixin, conversationMixin, alertMixin],
  props: {
    activeLabel: {
      type: String,
      default: '',
    },
    chat: {
      type: Object,
      default: () => {},
    },
    hideInboxName: {
      type: Boolean,
      default: false,
    },
    hideThumbnail: {
      type: Boolean,
      default: false,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    foldersId: {
      type: [String, Number],
      default: 0,
    },
    showAssignee: {
      type: Boolean,
      default: false,
    },
    conversationType: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    isInsideContactConversation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      showContextMenu: false,
      contextMenu: {
        x: null,
        y: null,
      },
      firstMetaComment: {},
      isLoading: false,
      loadingType: null,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      inboxesList: 'inboxes/getInboxes',
      activeInbox: 'getSelectedInbox',
      currentUser: 'getCurrentUser',
      accountId: 'getCurrentAccountId',
      colorScheme: 'colorScheme/getColorScheme',
    }),
    bulkActionCheck() {
      return !this.hideThumbnail && !this.hovered && !this.selected;
    },
    chatMetadata() {
      return this.chat.meta || {};
    },

    assignee() {
      return this.chatMetadata.assignee || {};
    },

    currentContact() {
      return this.$store.getters['contacts/getContact'](
        this.chatMetadata.sender.id
      );
    },

    isActiveChat() {
      return this.currentChat.id === this.chat.id;
    },

    unreadCount() {
      return this.chat.unread_count;
    },

    hasUnread() {
      return this.unreadCount > 0;
    },

    isInboxNameVisible() {
      return !this.activeInbox;
    },

    lastMessageInChat() {
      return this.lastMessage(this.chat);
    },

    inbox() {
      const { inbox_id: inboxId } = this.chat;
      const stateInbox = this.$store.getters['inboxes/getInbox'](inboxId);
      return stateInbox;
    },

    showInboxName() {
      return (
        !this.hideInboxName &&
        this.isInboxNameVisible &&
        this.inboxesList.length > 1
      );
    },
    inboxName() {
      const stateInbox = this.inbox;
      return stateInbox.name || '';
    },
    iconLib() {
      return icons;
    },
    isDarkMode() {
      return this.colorScheme === 'dark';
    },

    // get meta comment hidden status
    metaCommentHiddenStatus() {
      return this.$store.state.conversations.metaCommentHiddenStatus;
    },

    // get meta comment liked status
    metaCommentLikedStatus() {
      return this.$store.state.conversations.metaCommentLikedStatus;
    },

    // filter meta comment hidden status based on id
    getMetaCommentHiddentStatus() {
      if (
        !this.metaCommentHiddenStatus ||
        !Array.isArray(this.metaCommentHiddenStatus)
      ) {
        return null;
      }
      return this.metaCommentHiddenStatus.find(
        status => status.messageId === this.firstMetaComment.id
      );
    },

    shouldShowHideIcon() {
      return this.getMetaCommentHiddentStatus
        ? this.getMetaCommentHiddentStatus.isHidden
        : this.isMetaCommentHidden;
    },

    // filter meta comment liked status based on id
    getMetaCommentLikedtStatus() {
      if (
        !this.metaCommentLikedStatus ||
        !Array.isArray(this.metaCommentLikedStatus)
      ) {
        return null;
      }
      return this.metaCommentLikedStatus.find(
        status => status.messageId === this.firstMetaComment.id
      );
    },

    shouldShowLikeIcon() {
      return this.getMetaCommentLikedtStatus
        ? this.getMetaCommentLikedtStatus.isLiked
        : this.isMetaCommentLiked;
    },

    hasMetaAiDecision() {
      return !!this.firstMetaComment?.ai_decision;
    },

    isMetaCommentHidden() {
      return this.chat?.meta?.hidden || false;
    },

    isMetaCommentLiked() {
      return (
        this.firstMetaComment?.additional_attributes?.comment?.is_liked || false
      );
    },

    aiDecisionData() {
      return this.hasMetaAiDecision ? this.firstMetaComment?.ai_decision : null;
    },

    isOnMetaInbox() {
      const currentRoute = this.$route.name;
      const inboxRouteNames = ['inbox_dashboard', 'conversation_through_inbox'];
      return inboxRouteNames.includes(currentRoute);
    },

    isInstagramComment() {
      return (
        this.chat?.last_non_activity_message?.content_attributes?.comment
          ?.comment_type === META_COMMENT_TYPE_INSTAGRAM
      );
    },

    conversationId() {
      return this.firstMetaComment?.conversation_id;
    },

    messageId() {
      return this.firstMetaComment?.id;
    },

    getCommentType() {
      return this.firstMetaComment?.content_attributes?.comment?.comment_type;
    },

    tooltipForLike() {
      let content = '';
      if (this.shouldShowLikeIcon) {
        content = this.$t('CONVERSATION.ACTION.UNLIKE');
      } else {
        content = this.$t('CONVERSATION.ACTION.LIKE');
      }
      return content;
    },

    tooltipForHide() {
      let content = '';
      if (this.hasMetaAiDecision) {
        content = this.$t(`CHAT_LIST.COMMENT_HIDDEN`);
      }
      if (this.shouldShowHideIcon) {
        content = this.$t('CONVERSATION.ACTION.UNHIDE');
      } else {
        content = this.$t('CONVERSATION.ACTION.HIDE');
      }
      return content;
    },
  },
  mounted() {
    // Avoid refetch, because ConversationCard also used on sidepanel (right sidebar)
    if (
      this.$featureFlags['autohide-negative-comments'] &&
      !this.isInsideContactConversation
    ) {
      this.getFirstMetaComment();
    }
  },
  methods: {
    async getFirstMetaComment() {
      if (!this.isAMetaCommentInbox) {
        return;
      }

      // get first message on each chat
      try {
        const data = await this.$store.dispatch('fetchFirstMessage', {
          conversationId: this.chat.id,
        });

        this.firstMetaComment = data.message;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching the first meta comment:', error);
      }
    },
    onCardClick(e) {
      const { activeInbox, chat } = this;
      const path = frontendURL(
        conversationUrl({
          accountId: this.accountId,
          activeInbox,
          id: chat.id,
          label: this.activeLabel,
          teamId: this.teamId,
          foldersId: this.foldersId,
          conversationType: this.conversationType,
        })
      );

      if (e.metaKey || e.ctrlKey) {
        window.open(
          window.chatwootConfig.hostURL + path,
          '_blank',
          'noopener noreferrer nofollow'
        );
        return;
      }

      if (this.isActiveChat) {
        return;
      }

      router.push({ path });
    },
    onCardHover() {
      this.hovered = !this.hideThumbnail;
    },
    onCardLeave() {
      this.hovered = false;
    },
    onSelectConversation(checked) {
      const action = checked ? 'select-conversation' : 'de-select-conversation';
      this.$emit(action, this.chat.id, this.inbox.id);
    },
    openContextMenu(e) {
      e.preventDefault();
      this.$emit('context-menu-toggle', true);
      this.contextMenu.x = e.pageX || e.clientX;
      this.contextMenu.y = e.pageY || e.clientY;
      this.showContextMenu = true;
    },
    closeContextMenu() {
      this.$emit('context-menu-toggle', false);
      this.showContextMenu = false;
      this.contextMenu.x = null;
      this.contextMenu.y = null;
    },
    onUpdateConversation(status, snoozedUntil) {
      this.closeContextMenu();
      this.$emit(
        'update-conversation-status',
        this.chat.id,
        status,
        snoozedUntil
      );
    },
    async onAssignAgent(agent) {
      this.$emit('assign-agent', agent, [this.chat.id]);
      this.closeContextMenu();
    },
    async onAssignLabel(label) {
      this.$emit('assign-label', [label.title], [this.chat.id]);
      this.closeContextMenu();
    },
    async onAssignTeam(team) {
      this.$emit('assign-team', team, this.chat.id);
      this.closeContextMenu();
    },
    async markAsUnread() {
      this.$emit('mark-as-unread', this.chat.id);
      this.closeContextMenu();
    },
    async assignPriority(priority) {
      this.$emit('assign-priority', priority, this.chat.id);
      this.closeContextMenu();
    },
    setLoadingState(type) {
      this.isLoading = true;
      this.loadingType = type;
    },
    resetLoadingState() {
      this.isLoading = false;
      this.loadingType = null;
    },
    loadingState(type) {
      return this.isLoading && this.loadingType === type;
    },
    formatCommentType() {
      if (!this.getCommentType) return '';
      return (
        this.getCommentType.charAt(0).toUpperCase() +
        this.getCommentType.slice(1)
      );
    },
    async handleLikeComment(e) {
      if (!this.isOnMetaInbox || this.isLoading) {
        this.onCardClick(e);
        return;
      }

      this.setLoadingState('like');

      const actionName = this.shouldShowLikeIcon
        ? 'unlikeMetaComment'
        : 'likeMetaComment';

      const successMessage = !this.shouldShowLikeIcon
        ? 'CONVERSATION.ACTION.ALERT_LIKED'
        : 'CONVERSATION.ACTION.ALERT_UNLIKED';

      try {
        await this.$store.dispatch(actionName, {
          conversationId: this.conversationId,
          messageId: this.messageId,
        });

        if (this.shouldShowLikeIcon || !this.shouldShowLikeIcon) {
          this.showAlert(
            this.$t(successMessage, { type: this.formatCommentType() })
          );
        }
      } catch (error) {
        this.showAlert(`${error}`);
      } finally {
        this.resetLoadingState();
      }
    },
    async handleHideComment(e) {
      if (!this.isOnMetaInbox || this.isLoading) {
        this.onCardClick(e);
        return;
      }

      this.setLoadingState('hide');

      const actionName = this.shouldShowHideIcon
        ? 'unhideMetaComment'
        : 'hideMetaComment';

      const successMessage = !this.shouldShowHideIcon
        ? 'CONVERSATION.ACTION.ALERT_HIDDEN'
        : 'CONVERSATION.ACTION.ALERT_UNHIDDEN';

      try {
        await this.$store.dispatch(actionName, {
          conversationId: this.conversationId,
          messageId: this.messageId,
        });

        if (this.shouldShowHideIcon || !this.shouldShowHideIcon) {
          this.showAlert(
            this.$t(successMessage, { type: this.formatCommentType() })
          );
        }
      } catch (error) {
        this.showAlert(`${error}`);
      } finally {
        this.resetLoadingState();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.conversation {
  &.unread-chat {
    .unread {
      @apply block;
    }

    .conversation--message {
      @apply font-semibold;
    }

    .conversation--user {
      @apply font-semibold;
    }
  }

  &.compact {
    @apply pl-0;
    .conversation--details {
      @apply rounded-sm ml-0 pl-5 pr-2;
    }
  }

  &::v-deep .user-thumbnail-box {
    @apply mt-4;
  }

  &.conversation-selected {
    @apply bg-slate-25 dark:bg-slate-800;
  }

  &.has-inbox-name {
    &::v-deep .user-thumbnail-box {
      @apply mt-8;
    }

    .checkbox-wrapper {
      @apply mt-8;
    }

    .conversation--meta {
      @apply mt-4;
    }
  }

  .checkbox-wrapper {
    @apply h-10 w-10 flex items-center justify-center rounded-full cursor-pointer mt-4 hover:bg-woot-100 dark:hover:bg-woot-800;

    input[type='checkbox'] {
      @apply m-0 cursor-pointer;
    }
  }

  .moderation {
    @apply flex items-center justify-end gap-0.5 relative mt-1.5 select-none mr-[-5px];

    &__item {
      @apply flex items-center justify-center rounded-sm w-[24px] h-[24px] relative;
    }

    &__icon {
      @apply w-[15px] m-auto text-[#5647FF] dark:text-[#9389fe];
    }

    &__icon--action {
      @apply text-secondary dark:text-secondary-dark;
    }

    &__spinner {
      @apply ml-0 mt-0 p-0;

      &:before {
        @apply ml-[-8px];
      }
    }
  }
}
</style>
