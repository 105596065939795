<template>
  <woot-button
    v-if="isInHomepage"
    :size="size"
    variant="clear"
    color-scheme="secondary"
    class="-ml-3 text-black-900 dark:text-slate-300"
    icon="list"
    @click="onMenuItemClick"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { getSidebarItems } from 'dashboard/components/layout/config/default-sidebar';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';

export default {
  mixins: [uiSettingsMixin],
  props: {
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    sideMenuConfig() {
      return getSidebarItems(this.accountId);
    },
    getSecondaryMenuRouteNames() {
      // get the route name of secondary menu in homepage
      const { secondaryMenu } = this.sideMenuConfig;
      const activeSecondaryMenu = secondaryMenu.find(
        menuItem => menuItem.routes
      );
      return activeSecondaryMenu?.routes;
    },
    isInHomepage() {
      const currentRouteName = this.$route.name;
      return this.getSecondaryMenuRouteNames.includes(currentRouteName);
    },
  },
  methods: {
    onMenuItemClick() {
      bus.$emit(BUS_EVENTS.TOGGLE_SIDEMENU);
    },
  },
};
</script>
