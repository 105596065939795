import { frontendURL } from '../../../../helper/URLHelper';

const profileSettings = accountId => ({
  parentNav: 'profileSettings',
  routes: ['profile_settings_index'],
  menuItems: [
    {
      icon: 'edit',
      label: 'PROFILE_SETTINGS',
      hasSubMenu: false,
      toState: frontendURL(`accounts/${accountId}/profile/settings`),
      toStateName: 'profile_settings_index',
    },
  ],
});

export default profileSettings;
