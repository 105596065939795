<template>
  <div class="flex flex-row gap-8 flex-shrink min-w-0 p-6 overflow-auto h-full">
    <div class="w-[60%]">
      <div class="mb-2 text-sm">
        {{ $t('SIDEBAR.INTENT.TITLE_LABEL') }}
      </div>
      <input
        v-model="intentTitle"
        type="text"
        class="input-text"
        :class="{
          'is-focused': focusedField === 'titleField',
        }"
        :placeholder="$t('SIDEBAR.INTENT.QUESTION.PLACEHOLDER')"
        @focus="onFocus('titleField')"
        @blur="onBlur"
      />
      <div class="mb-2 text-sm">
        {{ $t('SIDEBAR.INTENT.DESCRIPTION.LABEL') }}
      </div>
      <resizable-text-area
        v-model="intentDescription"
        class="textarea"
        :class="{
          'is-focused': focusedField === 'descriptionField',
        }"
        :placeholder="$t('SIDEBAR.INTENT.QUESTION.PLACEHOLDER')"
        @focus="onFocus('descriptionField')"
        @blur="onBlur"
      />
      <div class="mb-2 text-sm">
        {{ $t('SIDEBAR.INTENT.RESPOND.LABEL') }}
      </div>
      <div
        class="editor"
        :class="{
          'is-focused': focusedField === 'instructionField',
        }"
      >
        <woot-message-editor
          v-model="intentInstruction"
          class="input"
          :placeholder="$t('SIDEBAR.INTENT.RESPOND.PLACEHOLDER')"
          @focus="onFocus('instructionField')"
          @blur="onBlur"
        />
      </div>
    </div>
    <div class="w-[34%]">
      <span v-dompurify-html="$t('SIDEBAR.INTENT.ADD_NEW_GUIDE')" />
    </div>
    <woot-button
      class="button button--fixed-top"
      :is-disabled="isDisabled"
      @click="handleSubmit"
    >
      <fluent-icon icon="add-circle" />
      {{ $t('SIDEBAR.INTENT.SAVE') }}
    </woot-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import WootMessageEditor from 'dashboard/components/widgets/WootWriter/Editor.vue';
import ResizableTextArea from 'shared/components/ResizableTextArea.vue';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    WootMessageEditor,
    ResizableTextArea,
  },
  mixins: [alertMixin],
  data() {
    return {
      intentTitle: '',
      intentDescription: '',
      intentInstruction: '',
      focusedField: null,
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.intentTitle || !this.intentDescription || !this.intentInstruction
      );
    },
  },
  methods: {
    ...mapActions('intents', ['create']),
    resetField() {
      this.intentTitle = '';
      this.intentDescription = '';
      this.intentInstruction = '';
    },
    async handleSubmit() {
      if (this.isDisabled) {
        return;
      }

      const intentObj = {
        title: this.intentTitle,
        description: this.intentDescription,
        instructions: this.intentDescription,
      };

      await this.create({
        enabled: true,
        ...intentObj,
      });

      this.resetField();
      this.showAlert('Successfully saved a new intent');

      await this.$router.push({ name: 'ai_home' });
    },
    onFocus(fieldName) {
      this.focusedField = fieldName;
    },

    onBlur() {
      this.focusedField = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-text {
  @apply w-full text-sm px-4 border-neutral-high dark:border-neutral-dark-medium;

  &:focus {
    @apply transition-all duration-100 ease-in;
  }
}

.textarea {
  @apply w-full dark:bg-primary border border-neutral-high dark:border-neutral-dark-medium max-h-[90px] min-h-[90px] resize-none text-sm p-4 rounded-lg transition-all duration-100 ease-in;
}

.editor {
  @apply px-4 pb-4 bg-neutral dark:bg-primary border border-neutral-high dark:border-neutral-dark-medium rounded-lg transition-all duration-100 ease-in;

  ::v-deep {
    .ProseMirror-woot-style {
      min-height: calc(100vh - 412px);
      max-height: calc(100vh - 412px);
    }

    .ProseMirror-focused {
      outline: none;
    }
  }
}

.button {
  svg {
    @apply relative top-[-1px];
  }
}

.disabled svg {
  @apply text-white;
}

.is-focused {
  @apply border-accent dark:border-accent-dark;
}
</style>
