<template>
  <div
    class="flex justify-between items-center h-14 min-h-[3.5rem] px-4 py-2 bg-white dark:bg-slate-900 border-b border-neutral-high dark:border-neutral-dark-medium"
  >
    <h1
      class="text-2xl mb-0 flex items-center text-slate-900 dark:text-slate-100"
    >
      <woot-sidemenu-icon v-if="showSidemenuIcon" />
      <back-button
        v-if="showBackButton"
        :button-label="backButtonLabel"
        :back-url="backUrl"
      />
      <fluent-icon
        v-if="icon"
        :icon="icon"
        :view-box="viewBox"
        class="mr-2 ml-4 rtl:ml-2 rtl:mr-4 header-icon"
      />
      <slot />
      <span class="text-slate-900 dark:text-slate-100">
        {{ headerTitle }}
      </span>
    </h1>
    <router-link v-if="showNewButton && isAdmin" :to="buttonRoute">
      <woot-button class="button button--fixed-top" color-scheme="success">
        <fluent-icon icon="add-circle" />
        {{ buttonText }}
      </woot-button>
    </router-link>
  </div>
</template>
<script>
import BackButton from '../../../components/widgets/BackButton.vue';
import adminMixin from '../../../mixins/isAdmin';

export default {
  components: {
    BackButton,
  },
  mixins: [adminMixin],
  props: {
    headerTitle: {
      default: '',
      type: String,
    },
    buttonRoute: {
      default: '',
      type: String,
    },
    buttonText: {
      default: '',
      type: String,
    },
    icon: {
      default: '',
      type: String,
    },
    showBackButton: { type: Boolean, default: false },
    showNewButton: { type: Boolean, default: false },
    backUrl: {
      type: [String, Object],
      default: '',
    },
    backButtonLabel: {
      type: String,
      default: '',
    },
    showSidemenuIcon: {
      type: Boolean,
      default: true,
    },
    viewBox: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .header-icon.dark-os,
  .header-icon.dark {
    path {
      @apply stroke-primary-dark;
    }
  }
}
</style>
