<template>
  <div>
    <form @click.prevent="">
      <label>
        {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.BRAND') }}

        <input
          v-model="brand"
          type="text"
          :placeholder="
            $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.BRAND_PLACEHOLDER')
          "
        />
      </label>

      <label>
        {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.EMAIL') }}

        <input
          v-model="email"
          type="email"
          :placeholder="
            $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.EMAIL_PLACEHOLDER')
          "
        />
      </label>

      <label>
        {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.TOKEN') }}

        <input v-model="token" type="password" />
      </label>
    </form>

    <div v-if="currentStage" class="mb-4">
      <div
        class="mt-3 border border-solid border-slate-200 dark:border-slate-600 rounded-md bg-white dark:bg-slate-900 p-3"
      >
        <div class="flex items-start">
          <img src="~dashboard/assets/images/cloud-download.svg" />

          <div class="ms-2">
            <h6 class="dark:text-slate-100 mb-0 text-sm">
              {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.STATUS_HEADING') }}
            </h6>

            <div v-if="status.stage == 'finished'">
              <div class="text-green-500 dark:text-green-500 text-sm">
                {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.FINISHED') }}
              </div>
            </div>

            <div v-else-if="status.stage === 'error'">
              <div class="text-red-500 dark:text-red-500 text-sm">
                {{
                  $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.ERROR').split(
                    supportEmail
                  )[0]
                }}
                <span
                  v-tooltip="{ content: 'Copy' }"
                  class="cursor-pointer hover:text-error-dark-medium dark:hover:text-error-dark-medium"
                  @click="handleCopy(supportEmail)"
                >
                  {{ supportEmail }}
                </span>
                {{
                  $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.ERROR').split(
                    supportEmail
                  )[1]
                }}
              </div>
            </div>

            <div v-else-if="status.stage === 'started'">
              <div class="text-slate-400 dark:text-slate-300 text-sm">
                {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.STARTED') }}
              </div>
            </div>

            <div
              v-else-if="status.stage === 'conversations'"
              class="text-slate-400 dark:text-slate-300 text-sm"
            >
              {{
                $t(
                  'GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.CONVERSATIONS_STATUS_MESSAGE',
                  { numProcessed, numTotal }
                )
              }}
            </div>

            <div
              v-else-if="status"
              class="text-slate-400 dark:text-slate-300 text-sm"
            >
              {{
                $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.STATUS_MESSAGE', {
                  stage: status.sage,
                })
              }}
            </div>
          </div>
        </div>

        <div
          v-if="showProgressBar"
          class="mt-3 bg-slate-300 h-2 rounded overflow-hidden"
        >
          <div
            class="bg-woot-300 h-full"
            :style="{ width: progressPercentage + '%' }"
          />
        </div>
      </div>
    </div>

    <div class="flex content-center">
      <woot-button :disabled="importInProgress" @click.prevent="startImport()">
        {{ $t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.IMPORT') }}
      </woot-button>
    </div>
  </div>
</template>

<script>
import ReamazeImporterAPI from '../../../../api/reamaze_importer';
import AlertMixin from 'shared/mixins/alertMixin';
import { copyTextToClipboard } from 'shared/helpers/clipboard';

const REAMAZE_IMPORT_STATUS_UPDATED = 'reamaze.import.status_updated';
const NUM_TOTAL_DIVISOR = 3; // 3 jobs per conversation on the back-end side

export default {
  mixins: [AlertMixin],
  data() {
    return {
      brand: '',
      email: '',
      token: '',
      status: null,
      supportEmail: 'karri@commslayer.com',
    };
  },
  computed: {
    currentStage() {
      if (!this.status || !this.status.stage || this.status.stage === '')
        return;
      return this.status.stage;
    },
    showProgressBar() {
      return this.status && !['error', 'finished'].includes(this.status.stage);
    },
    numProcessed() {
      return Math.floor((this.status.num_processed || 0) / NUM_TOTAL_DIVISOR);
    },
    numTotal() {
      return Math.floor((this.status.num_total || 1) / NUM_TOTAL_DIVISOR);
    },
    progressPercentage() {
      if (this.currentStage === 'started') return 1;
      if (this.currentStage === 'finished') return 100;
      return (this.numProcessed / this.numTotal) * 100;
    },
    importInProgress() {
      if (!this.currentStage) return false;
      return !['error', 'finished'].includes(this.currentStage);
    },
  },
  async mounted() {
    bus.$on(REAMAZE_IMPORT_STATUS_UPDATED, this.updateStatus);

    try {
      const response = await ReamazeImporterAPI.getStatus();
      if (response.status === 200) this.status = response.data;
    } catch (e) {
      this.status = null;
    }
  },
  methods: {
    async startImport() {
      try {
        const response = await ReamazeImporterAPI.startImport({
          brand: this.brand,
          email: this.email,
          token: this.token,
        });

        if (response.status === 201) this.status = response.data;
      } catch (e) {
        this.showAlert(
          this.$t('GENERAL_SETTINGS.FORM.REAMAZE_IMPORTER.UNAUTHORIZED')
        );
        this.status = { stage: 'error' };
      }
    },
    updateStatus(status) {
      // status updates come in asynchronously. to avoid flicker, we always display the highest value seen
      if (this.status?.num_processed && status.num_processed) {
        this.status.stage = status.stage;
        this.status.num_total = status.num_total;
        this.status.num_processed = Math.max(
          this.status.num_processed,
          status.num_processed
        );
      } else {
        this.status = status;
      }
    },
    async handleCopy(val) {
      await copyTextToClipboard(val);
      this.showAlert('Copied to clipboard successfully');
    },
  },
};
</script>
