<template>
  <div
    ref="helpElement"
    v-tooltip.right="tooltipOptions"
    class="help-menu"
    :class="{
      'justify-center relative': !isSidebarExpanded,
      'help-menu--active': isShowDropdown,
    }"
    @click="handleShowDropdown"
  >
    <div
      class="help-menu__prefix"
      :class="{ 'mr-2 rtl:mr-0 rtl:ml-2': isSidebarExpanded }"
    >
      <Icon
        size="18"
        view-box="0 -0.3 14 15"
        icon="question"
        type="outline"
        :icons="iconLib"
        is-in-sidebar
      />
    </div>
    <template v-if="isSidebarExpanded">
      <div class="help-menu__text">Help</div>
    </template>

    <!-- primary menu dropdown -->
    <div
      v-if="isShowDropdown"
      class="help-menu__wrapper"
      :style="dropdownStyle"
    >
      <div class="help-menu__body">
        <a
          v-for="(item, index) in helpMenuItems"
          :key="index"
          class="nav-item"
          :href="item.link"
          :target="index === 2 ? '_self' : '_blank'"
        >
          <Icon
            class="nav-item-icon"
            :size="item.iconSize"
            :icon="item.icon"
            type="outline"
            :icons="iconLib"
            :view-box="item.viewBox"
          />
          <span>{{ item.label }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'shared/components/FluentIcon/Icon.vue';
import icons from 'shared/components/FluentIcon/sidebar-icons.json';

export default {
  components: {
    Icon,
  },
  props: {
    isSidebarExpanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowDropdown: false,
      dropdownStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      helpMenuItems: [
        {
          icon: 'book',
          iconSize: '18',
          viewBox: '0 0 12 13',
          label: 'Help docs',
          link: 'https://app.commslayer.com/hc/help-center/en/',
        },
        {
          icon: 'board',
          iconSize: '19',
          label: 'Feature requests',
          viewBox: '0 0 14 13',
          link: 'https://commslayer.featurebase.app/dashboard/posts',
        },
        {
          icon: 'contact',
          iconSize: '20',
          label: 'Contact us',
          viewBox: '0 0 11 13',
          link: 'mailto:karri@commslayer.com',
        },
      ],
    };
  },
  computed: {
    iconLib() {
      return icons;
    },
    tooltipOptions() {
      if (this.isSidebarExpanded || this.isShowDropdown) {
        return { content: '', classes: '' };
      }
      return {
        content: 'Help',
        classes: 'new-sidebar-tooltip',
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.updateDropdownPosition);
      this.updateDropdownPosition();
    });
    window.addEventListener('mouseup', this.onOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDropdownPosition);
    window.removeEventListener('mouseup', this.onOutsideClick);
  },
  methods: {
    handleShowDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
      this.$nextTick(this.updateDropdownPosition);
    },
    handleCloseDropdown() {
      this.isShowDropdown = false;
    },
    onOutsideClick(e) {
      if (
        this.isShowDropdown &&
        e.target !== this.$refs.helpElement &&
        !this.$refs.helpElement.contains(e.target) &&
        !this.$refs.helpElement.contains(e.target)
      ) {
        this.handleCloseDropdown();
      }
    },
    updateDropdownPosition() {
      if (this.$refs.helpElement) {
        const parentRect = this.$refs.helpElement.getBoundingClientRect();
        this.dropdownStyle.top = `${parentRect.top - 124}px`;
        this.dropdownStyle.left = `${parentRect.left}px`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.help-menu {
  @apply flex
  items-center
  rounded-[4px]
  leading-4
  font-medium
  text-sm
  p-2
  mt-2
  h-[33px]
  text-secondary
  dark:text-secondary
  hover:text-secondary
  hover:bg-neutral-medium
  dark:hover:bg-[#222527]
  border
  border-transparent
  cursor-pointer;

  &--active {
    @apply bg-neutral-medium dark:bg-[#222527];
  }

  &__prefix {
    @apply w-[18px] h-[19px] relative;
  }
  &__text {
    @apply leading-none w-[80%];
  }

  &__wrapper {
    @apply w-[245px] bg-white dark:bg-[#222526] z-10 shadow-dropdown rounded-lg overflow-hidden border border-neutral-medium dark:border-neutral-dark-medium;
  }

  &__body {
    @apply max-h-[246px] overflow-y-auto px-2 py-1;
  }
}

.nav-item {
  @apply text-primary
  dark:text-primary-dark
  flex
  items-center
  rounded-[4px]
  leading-4
  m-0
  mt-0.5
  mb-0.5
  text-sm
  h-[33px]
  p-2
  hover:bg-neutral-low
  dark:hover:bg-primary
  relative
  w-full
  font-normal;
}
.nav-item-icon {
  @apply mr-2;
}
.nav-item-active {
  @apply bg-neutral-low dark:bg-primary;
}
.count-badge {
  @apply rounded-sm text-xxs mx-1 py-0 px-1 text-accent dark:text-accent-dark bg-accent-low dark:bg-accent-dark-low;
}
.nav-item-icon-active {
  @apply text-primary dark:text-primary-dark;
}
</style>
